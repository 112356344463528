import Markdown from 'markdown-to-jsx'
import { RadixButton } from '@/src/rebrand/buttonRadix'
import React from 'react'
import EllipsisIcon from '@/src/components/header/EllipsisIcon'
import Icon from '@/src/components/icons/Icon'
import colors from '@/src/rebrand/colors'
import PhoneSupport from '@/src/components/PhoneSupport'
import { getTranslate } from '@/src/utilities/i18n'
import BackButton from '@/src/components/button/BackButton'
import { useRouter } from 'next/router'
import { useApis } from '@/src/contexts/api-context'
import useProcessSuccessfulLogin from '@/src/hooks/useProcessSuccessfulLogin'
import Toaster from '@/src/utilities/toaster'

const DoubledAccount = () => {
  const router = useRouter()
  const { next } = useApis()
  const { t } = getTranslate()
  const processLogin = useProcessSuccessfulLogin()
  const { query } = router

  const { therapistId, diagnosis, redirectUrl, kind, socialUser, user } = router.query as Record<string, any>

  const handleBackToLogin = () => {
    delete router.query.kind
    delete router.query.socialUser
    return router.replace({ query: { ...router.query } })
  }

  const handleCreateNewAccount = async () => {
    const regex = /companyEmployee\[email\]=([^&]+)/
    const companyEmail = decodeURIComponent(router.asPath).match(regex)?.[1]

    // user is passed via query as string
    const appleUser = user && user !== 'undefined' ? JSON.parse(user) : {}
    console.log(kind, appleUser, 'appleUser')
    try {
      const response = await next.registerFromSocial({
        ...(query['companyEmployee[token]']
          ? {
              companyEmployee: {
                slug: query['companyEmployee[slug]'],
                companyEmail: companyEmail,
                metaWorkplaceMemberId: query['companyEmployee[metaWorkplaceMemberId]'],
                token: query['companyEmployee[token]'],
                code: query['companyEmployee[code]'],
              },
            }
          : {}),
        user: kind === 'apple' ? appleUser : undefined,
        socialUser,
      })

      processLogin({
        response,
        redirectUrl,
        email: companyEmail,
        therapistId: Number.parseInt(therapistId, 10),
        diagnosis,
        sendEvent: response.status !== 201,
      })
    } catch (err) {
      Toaster.error('errors.defaultError')
      console.log('double account error', err)
    }
  }

  return (
    <div className="fixed bottom-0 left-0 z-20 h-full w-full bg-black bg-opacity-30 tablet:static tablet:bg-transparent">
      <div className="mt-[47px] h-full rounded-3xl bg-white p-5 tablet:p-12">
        <div className="mb-8 block text-sm tablet:hidden">
          <BackButton
            text={t('component.doubledAccount.backToLogin')}
            size="xxs"
            onClick={handleBackToLogin}
            icon="back"
          />
        </div>

        <div className="flex flex-col items-center justify-center text-center">
          <EllipsisIcon color={colors.yellow[500]} className="mb-4 shrink-0 tablet:mb-6">
            <Icon icon="warning-rebrand" color="secondary" size="lg" width={30} height={30} />
          </EllipsisIcon>

          <h3 className="mb-4">{t('component.doubledAccount.headline')}</h3>
          <Markdown className="mb-6 text-sm">{t('component.doubledAccount.description')}</Markdown>
          <RadixButton onClick={handleBackToLogin} className="mb-3 w-full" size="medium">
            {t('component.doubledAccount.backToLogin')}
          </RadixButton>
          <RadixButton onClick={handleCreateNewAccount} variant="light" className="w-full" size="medium">
            {t('component.doubledAccount.createAccount')}
          </RadixButton>
        </div>
      </div>
      <div className="flex flex-col gap-3 py-5 text-sm">
        <div className="flex items-center">
          <Icon icon="mobile-phone" color="primary" size="sm" className="mr-3" />
          <Markdown>{t('registration.onlyPassword')}</Markdown>
        </div>
        <div className="flex items-center">
          <Icon icon="lock" color="primary" size="sm" className="mr-3" />
          <Markdown>{t('registration.lock')}</Markdown>
        </div>
      </div>
      <div className="flex justify-center">
        <PhoneSupport />
      </div>
    </div>
  )
}

export default DoubledAccount
