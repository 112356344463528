import { useApis } from '../../contexts/api-context'
import useSWRImmutable from 'swr/immutable'
import { useRouter } from 'next/router'

const fetchCompanyData = async (api, slug) => {
  const result = await api.getCompany(slug as string)
  return result.data
}

const useGetCompany = () => {
  const { api } = useApis()
  const router = useRouter()
  const { data, mutate } = useSWRImmutable(
    () => (router.query.slug ? 'getCompany' : null),
    () => fetchCompanyData(api, router.query.slug),
  )

  return { data, fetchCompany: mutate }
}

export default useGetCompany
