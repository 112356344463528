import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Link from 'next/link'
import { useRouter } from 'next/router'
import * as Sentry from '@sentry/nextjs'
import Markdown from 'markdown-to-jsx'
import WrappedFormConnected from '../components/formComponents/WrappedForm'
import SignUpHeader from '../components/SignUpHeader'
import PhoneSupport from '../components/PhoneSupport'
import UserActions, { USER_TYPE } from '../redux/UserRedux'
import { be, bm } from '../utilities/bliss'
import { fetchingSelector } from '../store/selectors/GeneralSelectors'
import type { UserStateType } from '../types/UserStateType'
import LoginButtons from '../components/loginButtons'
import { useAppSelector } from '../store/hooks'
import { getTranslate } from '../utilities/i18n'
import useGetCompany from '../hooks/api/useGetCompany'
import { RadixButtonLink } from '../rebrand/buttonRadix'
import useUser from '../hooks/useUser'
import Loader from '../components/Loader'
import { getAuthenticatedAppHref } from '../utilities/getAuthenticatedAppHref'
import DoubledAccount from '@/src/components/login/DoubledAccount'

type LoginContainerPropsType = {
  therapistId?: number
}

const LoginContainer: React.FC<LoginContainerPropsType> = ({ therapistId }) => {
  const router = useRouter()
  const dispatch = useDispatch()
  const { t } = getTranslate()
  const { data } = useGetCompany()
  const {
    user: { type: userType },
    isLoadingUser,
  } = useUser()
  const doubledAccount = router.query?.socialUser

  const { query } = router
  const emailIsInOtherCountry = useAppSelector((state) => state.general.emailIsInOtherCountry)
  const authorizationFetching = useAppSelector((state) => fetchingSelector(state, 'authorization'))
  const isCompanyEmployeeLogin = query['companyEmployee[token]']

  const queryPart = decodeURIComponent(router.asPath)
  const registerRedirectUrl = isCompanyEmployeeLogin ? `/register/employee?${queryPart.split('?')?.[1]}` : '/register'

  useEffect(() => {
    if (userType !== null && !router.query.params) {
      const url = getAuthenticatedAppHref(userType === USER_TYPE.CLIENT)
      router.replace(url)
    }
  }, [userType, router.query])

  if (isLoadingUser) {
    return <Loader visible loaderType="section-large" style={{ height: '100dvh', marginTop: '-20px' }} />
  }

  const handleSubmit = (data: UserStateType) => {
    const regex = /companyEmployee\[email\]=([^&]+)/
    const email = queryPart.match(regex)?.[1]

    dispatch(
      UserActions.loginRequest(
        data.email,
        data.password,
        therapistId,
        !!(router.query.diagnosis as string),
        router.query.redirectUrl,
        ...(isCompanyEmployeeLogin
          ? [
              {
                slug: query['companyEmployee[slug]'],
                companyEmail: email,
                metaWorkplaceMemberId: query['companyEmployee[metaWorkplaceMemberId]'],
                token: query['companyEmployee[token]'],
                code: query['companyEmployee[code]'],
              },
            ]
          : []),
      ),
    )
  }

  const handleSwitchToRegister = () => {
    const { endDate, startDate, service, token } = router.query
    let registerUrl = ['/register', '/register']
    if (therapistId && endDate && startDate && service) {
      registerUrl = ['/register/reservation/[therapistId]', `/register/reservation/${therapistId}`]
    }
    if (token) {
      registerUrl = ['/register/employee', '/register/employee']
    }
    router
      .push({ pathname: registerUrl[0], query: router.query }, { pathname: registerUrl[1], query: router.query })
      .catch((err) => {
        Sentry.captureException(err)
      })
  }

  return (
    <div className={bm('signup')}>
      <div className="container">
        <SignUpHeader backToIntroduction />
        <div className={be('signup', 'content')}>
          <div className={be('signup', 'row', '', 'row mt-16')}>
            <div className="col-12 col-lg-6 order-lg-2 mb-10 lg:mb-0">
              {doubledAccount ? (
                <DoubledAccount />
              ) : (
                <div className="card bg-default">
                  {emailIsInOtherCountry && <EmailIsInOtherCountryNotice />}
                  <LoginButtons buttonText={t('component.button.login')} />
                  <WrappedFormConnected
                    formName="login."
                    loading={authorizationFetching}
                    onClick={handleSwitchToRegister}
                    onSubmit={handleSubmit}
                    prefix=""
                    reduxType="user"
                    specificationName="loginSpec"
                    t={t}
                  />
                </div>
              )}
            </div>
            <div className="col-12 col-lg-6">
              <div className="px-6 desktop:px-10">
                <div className={be('signup', 'title')}>
                  {isCompanyEmployeeLogin && data?.imageUrl && (
                    <div className="mb-4 hidden max-w-[180px] big-tablet:block">
                      <img src={data?.imageUrl} alt={data.name} />
                    </div>
                  )}
                  <h1 className="h1 text-color-secondary">
                    {isCompanyEmployeeLogin ? t('login.employee.title') : t('login.title')}
                  </h1>
                  <PhoneSupport />
                  <RadixButtonLink href={registerRedirectUrl} variant="light" size="medium" className="mt-4">
                    {t('login.register.buttonLabel')}
                  </RadixButtonLink>
                </div>
                <div className="mt-6 max-w-[530px]">
                  <img alt="diagnosis-intro" className={be('signup', 'mosaic')} src="/images/mosaic-removebg.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const EmailIsInOtherCountryNotice: React.FC = () => {
  const { t } = getTranslate()
  return (
    <div className="mb-10">
      <h3 className="h3 mb-4 max-w-[400px] text-error">{t('component.emailInOtherCountry.headlineLogin')}</h3>
      <p className="text-base text-text-color-light">
        <Markdown>{t('component.emailInOtherCountry.textLogin')}</Markdown>
      </p>
      <div className="-m-4 flex items-center">
        <div className="p-4">
          <RadixButtonLink size="small" newWindow href={t('component.emailInOtherCountry.switchBtn.href')}>
            {t('component.emailInOtherCountry.switchBtn.text')}
          </RadixButtonLink>
        </div>
        <div className="p-4">
          <Link
            href={t('component.emailInOtherCountry.infoBtn.href')}
            className="text-center font-bold underline"
            rel="noopener noreferrer"
            target="_blank"
          >
            {t('component.emailInOtherCountry.infoBtn.text')}
          </Link>
        </div>
      </div>
    </div>
  )
}

export default LoginContainer
